import React, { useState } from "react"
import Menu from "../Menu";
import MenuItem from "../MenuItem";
import MenuButton from "../MenuButton";
import MenuMobileButton from "../MenuMobileButton";
import MenuMobile from "../MenuMobile";
import MenuMobileItem from "../MenuMobileItem";


export default function Header() {
  const [showMenu, setShowMenu] = useState(false)

  const updateShowMenu = (value) => {
    setShowMenu(!value)
  }

  return (
    <>
      <div className="ezds-menu-container">
        <Menu>
          <MenuItem to="/">{'Home'}</MenuItem>
          <MenuItem to="/photos">{'Photos'}</MenuItem>
          <MenuItem to="/information">{'Information'}</MenuItem>
          <MenuItem to="/seeAndDo">{'See & Do'}</MenuItem>
          <MenuItem to="/contactUs">{'Contact us'}</MenuItem>
          <MenuButton to="/contactUs">{'Book Now'}</MenuButton>
        </Menu>
      </div>
      <div className="ezds-mobile-menu-container">
        <div className="header-mobile-container">
          <MenuButton to="/contactUs">{'Book Now'}</MenuButton>
          <MenuMobileButton onToggle={() => updateShowMenu(showMenu)}></MenuMobileButton>
          {showMenu && (
            <div className="header-mobile-menu">
              <MenuMobile>
                <MenuMobileItem to="/">{'Home'}</MenuMobileItem>
                <MenuMobileItem to="/photos">{'Photos'}</MenuMobileItem>
                <MenuMobileItem to="/information">{'Information'}</MenuMobileItem>
                <MenuMobileItem to="/seeAndDo">{'See & Do'}</MenuMobileItem>
                <MenuMobileItem to="/contactUs">{'Contact us'}</MenuMobileItem>
              </MenuMobile>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
