import React, { useState } from "react"

export default function MenuMobileButton({ children, to, onToggle }) {
  const [toggle, setToggle] = useState(false)

  const updateToggle = (value) => {
    setToggle(!value)
  }

  return (<div role="button" className="ezds-menu-mobile-button" tabIndex={0} onClick={() => { updateToggle(toggle); onToggle() }}
    onKeyDown={() => { updateToggle(toggle); onToggle() }}>
    <div className={toggle ? 'ezds-menu-mobile-button--active' : 'ezds-menu-mobile-button--inactive'}>
      <div className="bar-1"></div>
      <div className="bar-2"></div>
      <div className="bar-3"></div>
    </div>
  </div>)
}
