import React from "react"

export default function Title() {
  return (
    <div className="ezds-title-container">
      <span className="ezds-title">Villa Fedrita</span>
      <span className="ezds-subtitle">Mountain view Sea Resort</span>
    </div>
  )
}
